import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { Loader } from 'components/lib';
import { AuthContext } from 'components/lib';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Autocomplete,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
  Slider,
  Drawer,
  useMediaQuery, 
  useTheme
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedDarkAtom } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { aiLabelsOptions, embedDomainsOptions, endpointOptions, scoringOptions } from 'helper/constants';

const customStyle = {
  borderRadius: '5px',
  backgroundColor: '#002b36',
  color: '#93a1a1',
  padding: '15px',
  margin: '10px 0',
  fontSize: '13px',
  width: '100%',
  height: 'auto',
  textAlign: 'left', // Align text to the left
  alignItems: 'left',
  overflowX: 'auto',
  '@media (max-width: 768px)': {
    fontSize: '11px',
    padding: '12px',
  },
  '@media (max-width: 480px)': {
    fontSize: '10px',
    padding: '10px',
  },
};

const primaryColor = '#e01296';

export function FeedConfig() {
  const [visibility, setVisibility] = useState('public');
  const [feedId, setFeedId] = useState('');
  const [feedName, setFeedName] = useState('');
  const [description, setDescription] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [status, setStatus] = useState('');
  const [scoring, setScoring] = useState('');
  const [includesAILABELS, setIncludeAILABELS] = useState([]);
  const [excludeAILABELS, setExcludeAILABELS] = useState([]);
  const [includeAuthorIds, setIncludeAuthorIds] = useState([]);
  const [excludeAuthorIds, setExcludeAuthorIds] = useState([]);
  const [channels, setChannels] = useState([]);
  const [embedDomains, setEmbedDomains] = useState([]);
  const [includeParams, setIncludeParams] = useState([]);
  const [excludeParams, setExcludeParams] = useState([]);
  const [openIncludeDialog, setOpenIncludeDialog] = useState(false);
  const [openExcludeDialog, setOpenExcludeDialog] = useState(false);
  const [selectedIncludeParams, setSelectedIncludeParams] = useState([]);
  const [selectedExcludeParams, setSelectedExcludeParams] = useState([]);
  const [publicFeeds, setPublicFeeds] = useState([]); // State for public feeds
  const [privateFeeds, setPrivateFeeds] = useState([]); // State for private feeds
  const [isNewFeed, setIsNewFeed] = useState(false);
  const [authorOptions, setAuthorOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [savedConfigId, setSavedConfigId] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSaveAs, setShowSaveAs] = useState(true);
  const [loading, setLoading] = useState(false);
  const [promotionName, setPromotionName] = useState('');
  const [selectedPromotionFeedId, setSelectedPromotionFeedId] = useState('');
  const [selectedPromotionFeedName, setSelectedPromotionFeedName] = useState('');
  const [promotionPercentage, setPromotionPercentage] = useState(0);
  const [aiLabelThresholds, setAiLabelThresholds] = useState({});
  const [removeAILabelThresholds, setRemoveAILabelThresholds] = useState({});
  const [showPromotionSection, setShowPromotionSection] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(true);

  const playgroundUrl = process.env.REACT_APP_PLAYGROUND_URL;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const togglePreviewDrawer = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };


  const authContext = useContext(AuthContext);

  const statusOptions = ['active', 'inactive'];

  // Fetch feeds based on visibility type
  const fetchFeeds = async (type) => {
    try {
      let result = await Axios({
        url: '/api/feed/configs',
        method: 'post',
        data: {
          visibility: type,
          console_account_id: authContext?.user?.account_id || null,
        },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error(`Error fetching ${type} feeds:`, error);
      setSnackbarMessage(`Error fetching ${type} feeds`);
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch both public and private feeds simultaneously
  const fetchAllFeeds = async () => {
    setLoading(true);
    try {
      const [publicFeedsData, privateFeedsData] = await Promise.all([
        fetchFeeds('public'),
        fetchFeeds('private'),
      ]);
      setPublicFeeds(publicFeedsData);
      setPrivateFeeds(privateFeedsData);

    } catch (error) {
      console.error('Error fetching all feeds:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch channels details based on channel ids
  const fetchChannelsDetails = async (channels) => {
    if (!channels || channels.length === 0) return [];
    try {
      let channelsString = channels.join(',');
      let result = await Axios({
        baseURL: process.env.REACT_APP_NEYNAR_API_URL,
        url: '/v2/farcaster/channel/bulk',
        method: 'get',
        headers: { api_key: process.env.REACT_APP_NEYNAR_API_KEY },
        params: { ids: channelsString, type: 'parent_url' },
      });
      return result?.data?.channels || [];
    } catch (error) {
      console.error('Error fetching channels details:', error);
      setSnackbarMessage('Error fetching channel details');
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch author details based on author ids
  const fetchAuthorDetails = async (authorIds) => {
    if (!authorIds || authorIds.length === 0) return [];
    try {
      let result = await Axios({
        url: '/api/farcaster/users/details',
        method: 'post',
        data: { fids: authorIds },
      });
      return result?.data?.data || [];
    } catch (error) {
      console.error('Error fetching author details:', error);
      setSnackbarMessage('Error fetching author details');
      setShowSnackbar(true);
      return [];
    }
  };

  // Fetch autocomplete options based on user input and type
  const fetchAutocompleteOptions = async (query, type) => {
    if (!query) return [];
    try {
      if (type === 'author_ids') {
        let result = await Axios({
          url: '/api/farcaster/users/search',
          method: 'get',
          params: { query: query },
        });
        return result?.data?.data.map((item) => ({ label: item.fname, value: item.fid })) || [];
      }
      if (type === 'channels') {
        let result = await Axios({
          baseURL: process.env.REACT_APP_NEYNAR_API_URL,
          url: '/v2/farcaster/channel/search',
          method: 'get',
          headers: { api_key: process.env.REACT_APP_NEYNAR_API_KEY },
          params: { q: query, limit: 20 },
        });
        return result?.data?.channels.map((item) => ({ label: item.name, value: item.url })) || [];
      }
      return [];
    } catch (error) {
      console.error(`Error fetching ${type} options:`, error);
      setSnackbarMessage(`Error fetching ${type} options`);
      setShowSnackbar(true);
      return [];
    }
  };

  const handleVisibilityChange = (event, newVisibility) => {
    if (newVisibility !== null) {
      setVisibility(newVisibility);
      setFeedId(''); // Reset feed ID when changing visibility
      resetFormFields(); // Reset form fields when visibility changes
      const feedsToUse = newVisibility === 'public' ? publicFeeds : privateFeeds;
      // Auto-select the first feed in the new visibility state if available
      if (feedsToUse.length > 0) {
        const firstFeed = feedsToUse[0];
        setFeedId(firstFeed.config_id);
        populateFeedDetails(firstFeed);
      }

      if(newVisibility === 'public') {
        setShowSaveAs(true);
      }
      setIsPreviewOpen(true);
    }
  };

  const handleFeedSelection = async (event) => {
    const selectedFeedId = event.target.value;
    if (selectedFeedId === 'new') {
      setIsNewFeed(true);
      resetFormFields();
    } else {
      setIsNewFeed(false);
      setFeedId(selectedFeedId);
      const feeds = visibility === 'public' ? publicFeeds : privateFeeds;
      const selectedFeed = feeds.find((feed) => feed.config_id === selectedFeedId);
      if (selectedFeed) {
        populateFeedDetails(selectedFeed);
      }
      setIsPreviewOpen(true);
    }
  };

  const resetFormFields = () => {
    setFeedId('');
    setFeedName('');
    setDescription('');
    setEndpoint('');
    setStatus('');
    setScoring('');
    setIncludeAILABELS([]);
    setExcludeAILABELS([]);
    setIncludeAuthorIds([]);
    setExcludeAuthorIds([]);
    setChannels([]);
    setEmbedDomains([]);
    setIncludeParams([]);
    setExcludeParams([]);
    setAuthorOptions([]);
    setChannelOptions([]);
    setPromotionName('');
    setSelectedPromotionFeedId('');
    setSelectedPromotionFeedName('');
    setSelectedExcludeParams([]);
    setSelectedIncludeParams([]);
    setPromotionPercentage(0);
  };

  const populateFeedDetails = async (feed) => {
    setFeedName(feed.name);
    setDescription(feed.description);
    setEndpoint(feed.endpoint);
    setStatus(feed.status);
    setScoring(feed.config.scoring || '');

    // Initialize AI label thresholds
    let initialIncludeThresholds = {};
    let initialExcludeThresholds = {};

    // Populate promotion fields based on existing config
    if (feed.config.promotion_filters && feed.config.promotion_filters.length > 0) {
      const promotion = feed.config.promotion_filters[0];
      setPromotionName(promotion.promotion_name || '');
      setSelectedPromotionFeedId(promotion.feed_id || '');
      setSelectedPromotionFeedName(
        [...publicFeeds, ...privateFeeds].find((f) => f.config_id === promotion.feed_id)?.name || ''
      );
      setPromotionPercentage(promotion.percent || 0);
      setShowPromotionSection(true);
    }else{
        setPromotionName('');
        setSelectedPromotionFeedId('');
        setSelectedPromotionFeedName('');
        setPromotionPercentage(0);
        setShowPromotionSection(false);
    }

    if (feed?.config?.filters?.channels && feed?.config?.filters?.channels?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'channels'])]);
      let channelsDetails = await fetchChannelsDetails(feed.config.filters.channels);
      setChannels(channelsDetails.map((ch) => ({ label: ch.name, value: ch.url })));
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'channels'])]);
    }else{
        setChannels([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'channels')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'channels'));
    }

    // Set thresholds for include AI labels
    if (feed?.config?.filters?.ai_labels && feed.config.filters.ai_labels.length > 0) {
        const aiLabelsWithThresholds = feed.config.filters.ai_labels.map(label => {
        const [labelName, threshold] = label.split(':');
        if (threshold) {
            initialIncludeThresholds[labelName] = parseFloat(threshold); // Store the threshold value
        }
        return labelName; // Store the label name
        });
        setIncludeAILABELS(aiLabelsWithThresholds);
        setSelectedIncludeParams(prev => [...new Set([...prev, 'ai_labels'])]);
    } else {
        setIncludeAILABELS([]);
        setSelectedIncludeParams(prev => prev.filter(param => param !== 'ai_labels'));
    }

    // Set thresholds for exclude AI labels
    if (feed?.config?.filters?.remove_ai_labels && feed.config.filters.remove_ai_labels.length > 0) {
        const aiLabelsWithThresholds = feed.config.filters.remove_ai_labels.map(label => {
        const [labelName, threshold] = label.split(':');
        if (threshold) {
            initialExcludeThresholds[labelName] = parseFloat(threshold); // Store the threshold value
        }
        return labelName; // Store the label name
        });
        setExcludeAILABELS(aiLabelsWithThresholds);
        setSelectedExcludeParams(prev => [...new Set([...prev, 'ai_labels'])]);
    } else {
        setExcludeAILABELS([]);
        setSelectedExcludeParams(prev => prev.filter(param => param !== 'ai_labels'));
    }

    // Set the initial AI label thresholds
    setAiLabelThresholds(initialIncludeThresholds);
    setRemoveAILabelThresholds(initialExcludeThresholds);

    if (feed?.config?.filters?.author_ids && feed?.config?.filters?.author_ids?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
      let authorDetails = await fetchAuthorDetails(feed.config.filters.author_ids);
      setIncludeAuthorIds(authorDetails.map((id) => ({ label: id.fname, value: id.fid })));
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
    }else{
        setIncludeAuthorIds([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'author_ids')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'author_ids'));
    }

    if (feed?.config?.filters?.remove_author_ids && feed?.config?.filters?.remove_author_ids?.length > 0) {
      setExcludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
      let authorDetails = await fetchAuthorDetails(feed.config.filters.remove_author_ids);
      setExcludeAuthorIds(authorDetails.map((id) => ({ label: id.fname, value: id.fid })));
      setSelectedExcludeParams((prev) => [...new Set([...prev, 'author_ids'])]);
    }else{
        setExcludeAuthorIds([]);
        setExcludeParams((prev) => prev.filter((param) => param !== 'author_ids')); 
        setSelectedExcludeParams((prev) => prev.filter((param) => param !== 'author_ids'));
    }

    if (feed?.config?.filters?.embed_domains && feed?.config?.filters?.embed_domains?.length > 0) {
      setIncludeParams((prev) => [...new Set([...prev, 'embed_domains'])]);
      setEmbedDomains(feed.config.filters.embed_domains);
      setSelectedIncludeParams((prev) => [...new Set([...prev, 'embed_domains'])]);
    }else{
        setEmbedDomains([]);
        setIncludeParams((prev) => prev.filter((param) => param !== 'embed_domains')); 
        setSelectedIncludeParams((prev) => prev.filter((param) => param !== 'embed_domains'));
    }
  };

  const handleScoringChange = (event) => {
    setScoring(event.target.value);
  };

  const handleEndpointChange = (event) => {
    setEndpoint(event.target.value);
    setScoring('');
    if(event.target.value !== 'casts/feed/trending' && event.target.value !== 'casts/feed/popular'){
        setAiLabelThresholds({});
        setRemoveAILabelThresholds({});
    }
  };

  const handleIncludeParamsDialog = () => {
    setOpenIncludeDialog(true);
  };

  const handleExcludeParamsDialog = () => {
    setOpenExcludeDialog(true);
  };

  const handleIncludeParamsChange = (event) => {
    const { name, checked } = event.target;
    setSelectedIncludeParams((prev) => (checked ? [...prev, name] : prev.filter((item) => item !== name)));
  
    if (!checked) {
      switch (name) {
        case 'author_ids':
          setIncludeAuthorIds([]);
          break;
        case 'channels':
          setChannels([]);
          break;
        case 'ai_labels':
          setIncludeAILABELS([]);
          setAiLabelThresholds({}); // Reset thresholds when AI labels are unchecked
          break;
        case 'embed_domains':
          setEmbedDomains([]);
          break;
        default:
          break;
      }
    }
  };
  
  const handleExcludeParamsChange = (event) => {
    const { name, checked } = event.target;
    setSelectedExcludeParams((prev) => (checked ? [...prev, name] : prev.filter((item) => item !== name)));
  
    if (!checked) {
      switch (name) {
        case 'author_ids':
          setExcludeAuthorIds([]);
          break;
        case 'ai_labels':
          setExcludeAILABELS([]);
          setRemoveAILabelThresholds({}); // Reset thresholds when AI labels are unchecked
          break;
        default:
          break;
      }
    }
  };  

  const handleSave = async () => {
    const config = {
      console_account_id: authContext?.user?.account_id || null,
      name: feedName,
      api_key: 'all',
      description,
      endpoint,
      status,
      visibility: 'private',
      config: {
        filters: {
          channels: channels.length > 0 ? channels.map((ch) => ch.value) : [],
          ai_labels: includesAILABELS.map(label => aiLabelThresholds[label] ? `${label}:${aiLabelThresholds[label]}` : label),
          remove_ai_labels: excludeAILABELS.map(label => removeAILabelThresholds[label] ? `${label}:${removeAILabelThresholds[label]}` : label),
          author_ids: includeAuthorIds.length > 0 ? includeAuthorIds.map((id) => id.value) : [],
          remove_author_ids: excludeAuthorIds.length > 0 ? excludeAuthorIds.map((id) => id.value) : [],
          embed_domains: embedDomains.length > 0 ? embedDomains : [],
        },
      },
    };
  
    if (showPromotionSection && promotionName && promotionName !== '' && selectedPromotionFeedId && selectedPromotionFeedId !== '') {
      config.config.promotion_filters = [{
        promotion_name: promotionName,
        feed_id: selectedPromotionFeedId,
        percent: promotionPercentage
      }];
    }
  
    try {
      if (isNewFeed) {
        if (scoring && scoring !== '') {
          config.config.scoring = scoring;
        }
        const response = await Axios.post('/api/feed/config', config);
        setSavedConfigId(response?.data?.data?.config_id);
        setFeedId(response?.data?.data?.config_id);
        setSnackbarMessage(`Feed created successfully! Your feed ID is ${response?.data?.data?.config_id}. Please use this feed ID in the API.`);
        setShowSnackbar(true);
        setShowSaveAs(true);
        setIsNewFeed(false);
        setIsPreviewOpen(true);
      } else {
        if (scoring && scoring !== '') {
          config.config.scoring = scoring;
        }
        config.config_id = feedId;
        await Axios.patch('/api/feed/config', config);
        setSnackbarMessage('Feed updated successfully');
        setShowSnackbar(true);
        setShowSaveAs(true);
        setIsPreviewOpen(true);
      }
    } catch (error) {
      console.error('Error saving feed configuration:', error);
      setSnackbarMessage(`Failed to save configuration ${error}`);
      setShowSnackbar(true);
    } finally {
      // Re-fetch both public and private feeds after saving or updating
      fetchAllFeeds();
    }
  }; 

  const handleSaveAs = () => {
    setIsNewFeed(true);
    setFeedId('');
    setVisibility('private');
    setFeedName(`${feedName}-copy`);
    setSnackbarMessage('Prepare to save as a new feed');
    setShowSnackbar(true);
    setShowSaveAs(false);
    // setIsPreviewOpen(true);
  };

  const handleDelete = async () => {
    try {
      // Make the API call to delete the feed
      await Axios({
        url: '/api/feed/config',
        method: 'delete',
        data: {
          config_id: feedId, // Use the current feedId state
        },
      });
  
      // Show success message in snackbar
      setSnackbarMessage('Feed Config deleted successfully');
      setShowSnackbar(true);
  
    const [publicFeedsData, privateFeedsData] = await Promise.all([
        fetchFeeds('public'),
        fetchFeeds('private'),
    ]);

    // Set the state for public and private feeds
    setPublicFeeds(publicFeedsData);
    setPrivateFeeds(privateFeedsData);
      
      // Reset the form fields and select the first feed from the updated list
      const updatedFeeds = visibility === 'public' ? publicFeedsData : privateFeedsData;
      if (updatedFeeds.length > 0) {
        const firstFeed = updatedFeeds[0];
        resetFormFields(); // Clear all form fields
        setFeedId(firstFeed.config_id);
        populateFeedDetails(firstFeed);
        setIsPreviewOpen(true);
      } else {
        // If no feeds are left, reset the fields
        resetFormFields();
      }
  
    } catch (error) {
      console.error('Error deleting feed configuration:', error);
      setSnackbarMessage('Failed to delete the feed configuration');
      setShowSnackbar(true);
    }
  };
  

  useEffect(() => {
    const fetchInitialFeeds = async () => {
        // Fetch feeds data directly
        const [publicFeedsData, privateFeedsData] = await Promise.all([
            fetchFeeds('public'),
            fetchFeeds('private'),
        ]);

        // Set the state for public and private feeds
        setPublicFeeds(publicFeedsData);
        setPrivateFeeds(privateFeedsData);

        // Determine which feeds to use based on visibility
        const initialFeeds = visibility === 'public' ? publicFeedsData : privateFeedsData;

        // Check if there are feeds available and select the first feed
        if (initialFeeds.length > 0) {
            const firstFeed = initialFeeds[0];
            resetFormFields(); // Clear all form fields
            setFeedId(firstFeed.config_id); // Set the Feed ID
            populateFeedDetails(firstFeed); // Populate the form fields with feed details
        }
    };

    fetchInitialFeeds();
}, []); // Add visibility as a dependency

  return (
    <Box p={3} maxWidth="800px" mx="auto">
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <Loader />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-end" mb={3}>
            <ToggleButtonGroup
              value={visibility}
              exclusive
              onChange={handleVisibilityChange}
              aria-label="visibility"
            >
              <ToggleButton value="public" aria-label="public" sx={{ color: visibility === 'public' ? primaryColor : 'inherit' }}>
                Templates
              </ToggleButton>
              <ToggleButton value="private" aria-label="private" sx={{ color: visibility === 'private' ? primaryColor : 'inherit' }}>
                My Feeds
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Typography variant="h5" mb={2}>Feeds</Typography>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel shrink={!!feedId}>Select a feed</InputLabel>
            <Select value={feedId} onChange={handleFeedSelection} label="Select a feed">
              {visibility === 'private' && <MenuItem value="new">Create New Feed</MenuItem>}
              {(visibility === 'public' ? publicFeeds : privateFeeds).map((feed) => (
                <MenuItem key={feed.config_id} value={feed.config_id}>{feed.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="h6" mt={3}>Feed Details</Typography>
          <Box display="flex" gap={2} my={2}>
            <TextField 
              label="Feed ID" 
              value={feedId} 
              inputProps={{ readOnly: true }} 
              variant="outlined" 
              sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }} 
              disabled={true} // Always read-only
            />
            <TextField 
              label="Feed Name" 
              value={feedName} 
              onChange={(e) => setFeedName(e.target.value)} 
              variant="outlined" 
              sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }} 
              disabled={visibility === 'public'} // Disable in public mode
            />
          </Box>

          <Box display="flex" gap={2} my={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel shrink={!!endpoint}>Endpoint</InputLabel>
              <Select 
                value={endpoint} 
                onChange={handleEndpointChange} 
                label="Endpoint"
                sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }}
                disabled={visibility === 'public'} // Disable in public mode
              >
                {endpointOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <InputLabel shrink={!!status}>Status</InputLabel>
              <Select 
                value={status} 
                onChange={(e) => setStatus(e.target.value)} 
                label="Status"
                sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }}
                disabled={visibility === 'public'} // Disable in public mode
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <TextField
            label="Description"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }}
            disabled={visibility === 'public'} // Disable in public mode
          />

          <Typography variant="h6" mt={3}>Feed Configurations</Typography>
          <Box display="flex" gap={2} alignItems="center" my={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel shrink={!!scoring}>Scoring</InputLabel>
              <Select 
                value={scoring} 
                onChange={handleScoringChange} 
                label="Scoring"
                sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }}
                disabled={visibility === 'public'} // Disable in public mode
              >
                {(scoringOptions[endpoint] || []).map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Include Params Section */}
          <Box display="flex" alignItems="center" gap={2} my={2}>
            <Typography>Include Params</Typography>
            <Button 
              variant="outlined" 
              startIcon={<AddCircleOutlineIcon />} 
              onClick={handleIncludeParamsDialog}
              disabled={visibility === 'public'} // Disable in public mode
            >
              Rule
            </Button>
          </Box>

          <Dialog open={openIncludeDialog} onClose={() => setOpenIncludeDialog(false)}>
            <DialogTitle>Select Include Params</DialogTitle>
            <DialogContent>
              {['author_ids', 'channels', 'ai_labels', 'embed_domains'].map((param) => (
                <FormControlLabel
                  key={param}
                  control={<Checkbox checked={selectedIncludeParams.includes(param)} onChange={handleIncludeParamsChange} name={param} />}
                  label={param}
                  disabled={visibility === 'public'} // Disable in public mode
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenIncludeDialog(false)}>Close</Button>
            </DialogActions>
          </Dialog>

          <Box my={2}>
            {selectedIncludeParams.includes('author_ids') && (
              <>
                <Typography variant="subtitle1">Author Ids</Typography>
                <Autocomplete
                  multiple
                  options={authorOptions}
                  value={includeAuthorIds}
                  onInputChange={async (e, newValue) => {
                    if (newValue) {
                      const options = await fetchAutocompleteOptions(newValue, 'author_ids');
                      setAuthorOptions(options);
                    }
                  }}
                  onChange={(e, newValue) => setIncludeAuthorIds(newValue)}
                  renderInput={(params) => <TextField {...params} label="Author Ids" variant="outlined" />}
                  sx={{ my: 2 }}
                  disabled={visibility === 'public'} // Disable in public mode
                />
              </>
            )}
            {selectedIncludeParams.includes('channels') && (
              <>
                <Typography variant="subtitle1">Channels</Typography>
                <Autocomplete
                  multiple
                  options={channelOptions}
                  value={channels}
                  onInputChange={async (e, newValue) => {
                    if (newValue) {
                      const options = await fetchAutocompleteOptions(newValue, 'channels');
                      setChannelOptions(options);
                    }
                  }}
                  onChange={(e, newValue) => setChannels(newValue)}
                  renderInput={(params) => <TextField {...params} label="Channels" variant="outlined" />}
                  sx={{ my: 2 }}
                  disabled={visibility === 'public'} // Disable in public mode
                />
              </>
            )}
           {selectedIncludeParams.includes('ai_labels') && (
                <>
                    <Typography variant="subtitle1">AI Labels</Typography>
                    <Autocomplete
                    multiple
                    options={aiLabelsOptions}
                    value={includesAILABELS}
                    onChange={(e, newValue) => setIncludeAILABELS(newValue)}
                    renderInput={(params) => <TextField {...params} label="AI Labels" variant="outlined" />}
                    sx={{ my: 2 }}
                    disabled={visibility === 'public'} // Disable in public mode
                    />
                  {(endpoint === 'casts/feed/trending' || endpoint === 'casts/feed/popular') && includesAILABELS.map((label, index) => (
                    <Box key={index} sx={{ my: 2 }}>
                        <Typography gutterBottom>{` Threshold for ${label} : ${aiLabelThresholds[label] ? aiLabelThresholds[label] : 0 }`}</Typography>
                        <Slider
                        value={aiLabelThresholds[label] || 0} // Use the stored threshold value or default to 0
                        onChange={(e, value) => setAiLabelThresholds(prev => ({ ...prev, [label]: value }))}
                        step={0.001}
                        min={0}
                        max={1}
                        valueLabelDisplay="auto" // Display value on the slider
                        sx={{ color: primaryColor }}
                        disabled={visibility === 'public'}
                        />
                    </Box>
                    ))}
                </>
            )}
            {selectedIncludeParams.includes('embed_domains') && (
              <>
                <Typography variant="subtitle1">Embed Domains</Typography>
                <Autocomplete
                  multiple
                  options={embedDomainsOptions}
                  value={embedDomains}
                  onChange={(e, newValue) => setEmbedDomains(newValue)}
                  renderInput={(params) => <TextField {...params} label="Embed Domains" variant="outlined" />}
                  sx={{ my: 2 }}
                  disabled={visibility === 'public'} // Disable in public mode
                />
              </>
            )}
          </Box>

          {/* Exclude Params Section */}
          <Box display="flex" alignItems="center" gap={2} my={2}>
            <Typography>Exclude Params</Typography>
            <Button 
              variant="outlined" 
              startIcon={<AddCircleOutlineIcon />} 
              onClick={handleExcludeParamsDialog}
              disabled={visibility === 'public'} // Disable in public mode
            >
              Rule
            </Button>
          </Box>

          <Dialog open={openExcludeDialog} onClose={() => setOpenExcludeDialog(false)}>
            <DialogTitle>Select Exclude Params</DialogTitle>
            <DialogContent>
              {['author_ids', 'ai_labels'].map((param) => (
                <FormControlLabel
                  key={param}
                  control={<Checkbox checked={selectedExcludeParams.includes(param)} onChange={handleExcludeParamsChange} name={param} />}
                  label={param}
                  disabled={visibility === 'public'} // Disable in public mode
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenExcludeDialog(false)}>Close</Button>
            </DialogActions>
          </Dialog>

          <Box my={2}>
            {selectedExcludeParams.includes('author_ids') && (
              <>
                <Typography variant="subtitle1">Exclude Author Ids</Typography>
                <Autocomplete
                  multiple
                  options={authorOptions}
                  value={excludeAuthorIds}
                  onInputChange={async (e, newValue) => {
                    if (newValue) {
                      const options = await fetchAutocompleteOptions(newValue, 'author_ids');
                      setAuthorOptions(options);
                    }
                  }}
                  onChange={(e, newValue) => setExcludeAuthorIds(newValue)}
                  renderInput={(params) => <TextField {...params} label="Exclude Author Ids" variant="outlined" />}
                  sx={{ my: 2 }}
                  disabled={visibility === 'public'} // Disable in public mode
                />
              </>
            )}
            {selectedExcludeParams.includes('ai_labels') && (
                <>
                    <Typography variant="subtitle1">Exclude AI Labels</Typography>
                    <Autocomplete
                    multiple
                    options={aiLabelsOptions}
                    value={excludeAILABELS}
                    onChange={(e, newValue) => setExcludeAILABELS(newValue)}
                    renderInput={(params) => <TextField {...params} label="Exclude AI Labels" variant="outlined" />}
                    sx={{ my: 2 }}
                    disabled={visibility === 'public'} // Disable in public mode
                    />
                    {(endpoint === 'casts/feed/trending' || endpoint === 'casts/feed/popular') && excludeAILABELS.map((label, index) => (
                    <Box key={index} sx={{ my: 2 }}>
                        <Typography gutterBottom>{`Threshold for ${label} : ${removeAILabelThresholds[label] ? removeAILabelThresholds[label] : 0}`}</Typography>
                        <Slider
                        value={removeAILabelThresholds[label] || 0} // Use the stored threshold value or default to 0
                        onChange={(e, value) => setRemoveAILabelThresholds(prev => ({ ...prev, [label]: value }))}
                        step={0.001}
                        min={0}
                        max={1}
                        valueLabelDisplay="auto" // Display value on the slider
                        sx={{ color: primaryColor }}
                        disabled={visibility === 'public'}
                        />
                    </Box>
                    ))}
                </>
            )}
          </Box>

           {/* Promotion Section */}
           <Box display="flex" alignItems="center" gap={2} my={2}>
            <Typography>Promotions</Typography>
            <Button 
                variant="outlined" 
                startIcon={<AddCircleOutlineIcon />} 
                onClick={() => {
                setShowPromotionSection(!showPromotionSection);
                if (!showPromotionSection) {
                    // Reset promotion fields when closing
                    setPromotionName('');
                    setSelectedPromotionFeedId('');
                    setSelectedPromotionFeedName('');
                    setPromotionPercentage(0);
                }
                }}
                disabled={visibility === 'public'} // Disable in public mode
            >
                Rule
            </Button>
            </Box>

            {showPromotionSection && (
            <Box gap={2} my={2}>
                <TextField
                label="Promotion Name"
                value={promotionName}
                onChange={(e) => setPromotionName(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }}
                disabled={visibility === 'public'} // Disable in public mode
                />

                <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Select Feed for Promotion</InputLabel>
                <Select
                    value={selectedPromotionFeedId}
                    onChange={(e) => {
                    const feedId = e.target.value;
                    setSelectedPromotionFeedId(feedId);
                    const feed = [...publicFeeds, ...privateFeeds].find((f) => f.config_id === feedId);
                    setSelectedPromotionFeedName(feed ? feed.name : '');
                    }}
                    label="Select Feed for Promotion"
                    disabled={visibility === 'public'} // Disable in public mode
                >
                    {publicFeeds.concat(privateFeeds)
                    .filter((f) => f.endpoint === endpoint && f.config_id !== feedId)
                    .map((feed) => (
                        <MenuItem key={feed.config_id} value={feed.config_id}>
                        {feed.name}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>

                <Box display="flex" gap={2} my={2}>
                <TextField
                    label="Feed ID"
                    value={selectedPromotionFeedId}
                    fullWidth
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                    sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }}
                    disabled // Always read-only
                />

                <TextField
                    label="Feed Name"
                    value={selectedPromotionFeedName}
                    fullWidth
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                    sx={{ '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: primaryColor } }}
                    disabled // Always read-only
                />
                </Box>

                <Typography gutterBottom>{`Percentage  : ${promotionPercentage ? promotionPercentage : 0}`}</Typography>
                <Slider
                value={promotionPercentage}
                onChange={(e, value) => setPromotionPercentage(value)}
                step={1}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                sx={{ color: primaryColor }}
                disabled={visibility === 'public'} // Disable in public mode
                />
            </Box>
            )}


          <Box display="flex" gap={2}>
            {visibility === 'private' && (
              <>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    sx={{ mt: 3, backgroundColor: primaryColor, '&:hover': { backgroundColor: '#d00e83' } }}
                    disabled={visibility === 'public'} // Update is disabled on public feeds
                >
                    {isNewFeed ? 'Save Feed' : 'Update Feed'}
                </Button>
                {!isNewFeed && (
                    <Button
                    variant="contained"
                    color="error"
                    onClick={handleDelete}
                    sx={{ mt: 3, backgroundColor: '#d00e83', '&:hover': { backgroundColor: '#b00b6d' } }}
                    >
                    Delete Feed
                    </Button>
                )}
              </>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSaveAs}
              sx={{ mt: 3, backgroundColor: primaryColor, '&:hover': { backgroundColor: '#d00e83' } }}
              disabled={!showSaveAs}
            >
              {visibility === 'public' ? 'Use Template' : 'Clone This Feed'}
            </Button>
            {!isMobile &&
            <Button
              variant="contained"
              color="primary"
              onClick={togglePreviewDrawer}
              sx={{ mt: 3, backgroundColor: primaryColor, '&:hover': { backgroundColor: '#d00e83' } }}
            >
              Feed Preview
            </Button>}
          </Box>

          <Snackbar open={showSnackbar} autoHideDuration={9000} onClose={() => setShowSnackbar(false)}>
            <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>

        {isMobile ? (
          <Box
            sx={{
              width: '100%',
              mt: 3, 
              position: 'relative', 
              bottom: 0,
              display: feedId ? 'block' : 'none', 
            }}
          >
            <Typography variant="h6">Feed Preview</Typography>
            <Box
              sx={{
                mt: 2,
                overflowY: 'auto',
                height: '80vh', 
              }}
            >
              {feedId ? (
                <iframe
                  src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                  width="100%" // Full width
                  height="100%" // Full height within the container
                  style={{ border: 'none' }}
                  title="Feed Preview"
                ></iframe>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Please select a feed.
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          // Desktop view: render the drawer as usual
          <Drawer
            anchor="right"
            open={isPreviewOpen}
            onClose={togglePreviewDrawer}
            PaperProps={{
              sx: {
                width: '600px',
                height: '100%',
                overflow: 'hidden',
              },
            }}
          >
            <Box p={2} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">Feed Preview</Typography>
              <Box
                sx={{
                  flex: 1,
                  overflowY: 'auto',
                  mt: 2,
                }}
              >
                {feedId ? (
                  <iframe
                    src={`${playgroundUrl}/Feed_Config?feed-id=${feedId}`}
                    width="100%"
                    height="100%"
                    style={{ border: 'none', minHeight: 'calc(100vh - 120px)' }}
                    title="Feed Preview"
                  ></iframe>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Please select a feed.
                  </Typography>
                )}
              </Box>
            </Box>
          </Drawer>
        )}

          {(
            <Box mt={3}>
              <Typography variant="body1">Example usage of your feed ID in the APIs:</Typography>
              <SyntaxHighlighter language="javascript" style={solarizedDarkAtom} showLineNumbers customStyle={customStyle}>
                {`// API request body:
{
 // use your feed id like this below
   "feed_id": '${feedId}',

// other parameters for the request that you can use

   "user_id": "1",
   "return_metadata": true,

   "top_k": 10,   // not for trending and popular feeds

   "page_size": 3,   // only in trending and popular feeds
   "page_number": 1,  // only in trending and popular feeds
 }`}
              </SyntaxHighlighter>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
