/***
*
*   DASHBOARD
*
**********/

import {useState, React, useEffect, Fragment} from 'react';
import { Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, Feedback, useAPI,Form, Button, Label, Content, GroupCardsContainer, Loader } from 'components/lib';
import Axios from 'axios';
import Style from './dashboard.tailwind.js';
import { apiKeyNames } from '../../helper/enum';
import { GetStarted, Header, SubHeader, Link } from './getStarted.js';
import { Card as MUICard, CardContent, Typography, Box, Avatar,  useMediaQuery, useTheme, Grid as MGrid } from '@mui/material';
import logo_image from './logo_image.png';
import {computeUnitsPerApiCall, mbdUsagePlans} from 'helper/constants'
import { Chart as ChartJS } from 'react-chartjs-2';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
const _ = require('lodash');

// TODO:  move this to common settings
const GRAPH_COLORS = [
  "#A5B8E0", 
  "#76C7F2", 
  "#80D6B7", 
  "#8CD790",
  "#5BC8C7", 
  "#A3D477", 
  "#F39C9F", 
  "#FFB785", 
  "#FFD67B", 
  "#FFF07C", 
  "#FFB07C", 
  "#FF9AA2", 
  "#FF99A8", 
  "#F8A5A8", 
  "#D6A3F9" 
] 

export function Dashboard(props){

  const [frequency, setFrequency] = useState('day')
  const [period, setPeriod] = useState('1 month')
  const [perApiUsageChart, setPerApiUsageChart] = useState({loading: true});
  const [perApiComputeUsage, setPerApiComputeUsage] = useState([]);
  const [totalCopmuteUsed, setTotalComputeUsed] = useState(0);
  const [userComputeQuota, setUserComputeQuota] = useState(0);

  const [perApiUsageChartColor, setPerApiUsageChartColor] = useState(perApiUsageChart?.data?.colors || []);
 
  const messageText = `This is your dashboard to get you started. Please read the documentation `;
  const [apiTilesData, setApiTilesData] = useState(perApiUsageChart?.data?.colors || {});
  const [appUrlList, setAppUrlList] = useState([{"value": "all","label": "All"}]);
  const [appNameList, setAppNameList] =  useState([{"value": "all","label": "All"}]);
  const [appUrl, setAppUrl] = useState('all');
  const [appName, setAppName] = useState('all');
  const [groupCardsData, setGroupCardsData] = useState({});

  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  let darkMode = user?.dark_mode || false;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getPerAPIChartFilters  = async () => {
     

      let result = await Axios({

        url: '/api/dashboard/app-list-details',
        method: 'get',
        
      });
      setAppUrlList(result?.data?.data?.appUrlList || []);
      setAppNameList(result?.data?.data?.appNameList || []);
    };

    getPerAPIChartFilters();
    
    

  }, []);

  const getComputeUsagePieChartData = (apiData) => {

    let chartData = [];
    apiData.datasets.forEach((entry, i) => {
      if (entry.label in computeUnitsPerApiCall) {
        let datapoint = {}
        let sum = entry.data.reduce((sum, current) => sum + current, 0);
        sum *= computeUnitsPerApiCall[entry.label];
        datapoint = {
          id: i,
          value: sum, // Math.random() * (100000 - 50000) + 50000, // to test with random values
          label: `${entry.label} (${sum} CU)`,
          color: GRAPH_COLORS[i]
        }
        chartData.push(datapoint);

      }
    });
    return chartData;
  }
 

  useEffect(() => {

    const getPerAPIChartData  = async () => {
      setPerApiUsageChart({loading: true});
      let  result = await Axios({

        url: '/api/dashboard/api_usage',
        method: 'get',
        params: {frequency: frequency, period: 'this-month', app_url: appUrl, app_name: appName}
        
      });
      // console.log("getPerAPIChartData] api result: ", result);
     
      setPerApiUsageChart({ data: result.data.data, loading: false });
      setPerApiUsageChartColor(result?.data?.data?.colors || []);
      if(result?.data?.data?.tilesData){
        const tilesData =  _.cloneDeep(result?.data?.data?.tilesData) || {};
        const groupedTilesData = {
          "casts-feed": {},
          "users-feed": {},
          "search": {},
          "labels": {}
        }
       
        Object.keys(tilesData).forEach((endpoint) => {
          // console.log(endpoint.includes('feed'))
          
          if(endpoint.includes('casts-feed')){
            groupedTilesData["casts-feed"][endpoint] = tilesData[endpoint];
          }
          else if (endpoint.includes('users-feed')){
            groupedTilesData["users-feed"][endpoint] = tilesData[endpoint];
          }
          else if (endpoint.includes('search')){
            groupedTilesData["search"][endpoint] = tilesData[endpoint];
          }
          else if (endpoint.includes('labels')){
            groupedTilesData["labels"][endpoint] = tilesData[endpoint];
          }
        });
        setGroupCardsData(groupedTilesData);
        setApiTilesData(result?.data?.data?.tilesData || {});
      }
      const pieChartData = getComputeUsagePieChartData(result.data.data);
      setPerApiComputeUsage(pieChartData);
      const totalComputeUsed = pieChartData.reduce((sum, current) => sum + current.value, 0);
      setTotalComputeUsed(totalComputeUsed);
      setUserComputeQuota(mbdUsagePlans.BUILDR.cuQuota);

      
    };

    getPerAPIChartData();
    
    

  }, [frequency, period, appUrl, appName]);

 
  const handleFrequencyChange = (selectedFrequency) => {
    setFrequency(selectedFrequency?.value);
  };

  const handlePeriodChange = (selectedPeriod) => {
    setFrequency('day');
    setPeriod(selectedPeriod?.value);
  };

  const handleAppUrlChange = (selectedAppUrl) => {
    setAppUrl(selectedAppUrl?.value);
  };

  const handleAppNameChange = (selectedAppName) => {
    setAppName(selectedAppName?.value);
  };

  const handleStartClick = () => {
    window.open('https://playground.mbd.xyz/', '_blank');
  };

  const handleViewDocsClick = () => {
    window.open('https://docs.mbd.xyz', '_blank');
  };


  const frequencyOptions = [
    { value: 'day', label: '1 day' },
    // { value: 'minute', label: '15 min' },
    { value: 'hour', label: '1 hour' }
  ];

  // Options for Period select input
  const periodOptions = [
    { value: '1 month', label: '1 month' },
    { value: '1 day', label: '1 day' },
    { value: '1 week', label: '1 week' },
    // { value: '3 months', label: '3 months' },
    // { value: 'all', label: 'All' }
  ];

  const PercentUsageBar = (props) =>               
    <BarChart
      xAxis={[{
        scaleType: 'band',
        data: ['usage'],
        categoryGapRatio:0.5,
      }]}
      series={[
        {
          data: [props.percent],
          label: '% cu used',
          stack: 'usage',
          color: ['#76AFE8'],
          cornerRadius: 5,
          barGapRatio: 0.1,
          barLabel: `{props.percent}%`
        },
        {
          data:[100-props.percent],
          label: '% remaining',
          stack: 'usage',
          color: ['#F8F8F8'],
          cornerRadius: 5,
          barGapRatio: 0.1
        }

      ]}
      slotProps={{ legend: { 
        hidden: true,
        position: { vertical: 'bottom', horizontal: 'middle' },
      } }}
      barThickness={10}
      borderRadius={5}
      height={280}
      margin = {{top: 20}}
      leftAxis={null}
      bottomAxis={null}
      barLabel = {(item, context) => <p style={{color:'#ffffff'}}>`${item.value}%`</p>}
    />
  
    const PerAPICUPieChart = (props) => 
      <PieChart
        series={[
          {
            data: props.data,
            cornerRadius: 3,
          }
        ]}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            labelStyle: {
              fontSize: 9,
              fill: '#666666'
            },
            itemMarkWidth:9,
            itemMarkHeight:9,
            itemGap:9,
            margin: { top: 20}
          },
        
        }}
        margin={{top: 20}}
        height={250}
      />

      // console.log("=====> total used: ", totalCopmuteUsed, ", quota: ", userComputeQuota);
  
  return (
    <Animate type='pop'>

      {/* <Message
        closable
        title="Welcome to 'mbd console!"
        text={messageText}
        endTextLink = 'https://docs.mbd.xyz/reference'
        endText = 'here.'
      /> */}

      <Header>
        <span style={{justifyContent: 'space-between', display: 'flex'}}>
          <Typography variant={isMobile ? "h6" : "h5"}  style = {{color : darkMode ? "#fff" : "#000"}} component="div">
            Welcome {user?.name}!
          </Typography>
          <Avatar src={logo_image} alt="Company Logo" style={{ width: isMobile ? 35 : 45, height: isMobile ? 35 : 45, paddingBottom: 5}}/>
          </span>
        </Header>
      <GetStarted  darkMode = {darkMode}/>

      {/* {Object.keys(apiTilesData).length && (
        <Grid cols={Object.keys(apiTilesData).length + 1}>
          {Object.keys(apiTilesData).map((endpoint) => (
            <Stat
              key={endpoint} // Don't forget to add a unique key for each Stat component
              loading={apiTilesData?.loading}
              value={apiTilesData[endpoint]}
              label={endpoint}
              icon='refresh-cw'
            />
          ))}
        </Grid>
      )} */}

      <Card name='compute_unit_usage' 
            title='Monthly Compute Units (CU) Usage' 
            subTitle="( resets on the 1st of each month )"
            loading={perApiUsageChart.loading}>
          <MGrid container minHeight={350}>
            <MGrid justifyContent={'center'} xs={12} sm={4} md={3}>
              <Card>
                  {/* <Typography variant="body2" align="center" style={{color: '#94a3b8'}}>
                    % CU usage
                  </Typography> */}
                  <span width="100%">
                    <h2 style={{textAlign: 'center', color: '#94a3b8'}}>% CU Usage</h2>
                  </span>
                  <PercentUsageBar percent={totalCopmuteUsed/userComputeQuota*100}/>
                  <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
                    {`${(totalCopmuteUsed/userComputeQuota*100).toFixed(1)}%`}
                  </Typography>
                  <Typography variant="h6" align="center" style={{color: '#94a3b8', fontSize:12}}>
                    {`${totalCopmuteUsed} / ${userComputeQuota}`}
                  </Typography>
              </Card>
            </MGrid>
            <MGrid justifyContent={'center'} xs={12} sm={8} md={9}>
                <Card>
                  {/* 
                  <Typography variant="caption" align="center" style={{color: '#94a3b8'}}>
                    API CU Usage Distribution
                  </Typography> */}
                  <span width="100%">
                  <h2 style={{textAlign: 'center', color: '#94a3b8'}}>API CU Usage Distribution</h2>
                  </span>
                  <PerAPICUPieChart data={perApiComputeUsage}/>
                </Card>
            </MGrid>
          </MGrid>
      </Card>
      <Card name='api_usage' title='Monthly API Usage' subTitle="( resets on the 1st of each month )" loading={perApiUsageChart.loading}>
        <GroupCardsContainer groupedTilesData={groupCardsData} />
      </Card>

      <br/>

      {/* <Card name='api_usages' title='Monthly API Usage (resets on the 1st of each month)'>
        <Grid cols={4}>
            <Form
            inputs={{
              frequency: {
                label: 'Frequency',
                type: 'select',
                required: true,
                default:{ value: 'day', label: '1 day' },
                options:  period !== '1 day' ? frequencyOptions.filter(option => option.value === 'day') : frequencyOptions
              }
            }}
            updateOnChange
            onChange= {handleFrequencyChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'Period',
                type: 'select',
                required: true,
                default:{ value: '1 month', label: '1 month' },
                options: periodOptions
              }
            }}
            updateOnChange
            onChange= {handlePeriodChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'App Name',
                type: 'select',
                required: true,
                default:{"value": "all","label": "All"},
                options: appNameList || [{"value": "all","label": "All"}]
              }
            }}
            updateOnChange
            onChange= {handleAppNameChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'App Url',
                type: 'select',
                required: true,
                default:{"value": "all","label": "All"},
                options: appUrlList || [{"value": "all","label": "All"}]
              }
            }}
            updateOnChange
            onChange= {handleAppUrlChange}
          />
        </Grid>
        <Card>
          <Chart
            type='line'
            legend
            loading={ perApiUsageChart.loading }
            data={perApiUsageChart.data }
            color={perApiUsageChartColor}
          />
        </Card>
      </Card> */}

      <Feedback />

    </Animate>
  );
}
