exports.aiLabelsOptions = [
    'arts_culture',
    'business_entrepreneurs',
    'celebrity_pop_culture',
    'diaries_daily_life',
    'family',
    'fashion_style',
    'film_tv_video',
    'fitness_health',
    'food_dining',
    'gaming',
    'learning_educational',
    'music',
    'news_social_concern',
    'other_hobbies',
    'relationships',
    'science_technology',
    'sports',
    'travel_adventure',
    'youth_student_life',
    'positive',
    'neutral',
    'negative',
    'anger',
    'anticipation',
    'disgust',
    'fear',
    'joy',
    'love',
    'optimism',
    'pessimism',
    'sadness',
    'surprise',
    'trust',
    'llm_generated',
    'spam',   
    'sexual', 
    'hate',
    'violence',
    'harassment',
    'selfharm',
    'sexual_minors',
    'hate_threatening',
    'violence_graphic',
    'web3_nft',
    'web3_defi',
    'web3_infra',
    'web3_industry',
    'web3_consumer'
];


exports.embedDomainsOptions = [
    // nft
    "zora.co", 
    "moshi.cam",
    "wallet.coinbase.com",
    "superrare.com", 
    "rarible.com",  
    "manifold.xyz",
    "highlight.xyz",
    "mint.fun", 
    "opensea.io",
    "sorare.com",
    "magiceden.io",
    "blur.io",
    "looksrare.org",
    "niftygateway.com",
    // articles
    "paragraph.xyz", 
    "mirror.xyz", 
    "t2.world",
    "medium.com",
    "coindesk.com",
    "decrypt.co",
    "theblock.co",
    "bitcoinmagazine.com",
    "blockworks.co",
    "thedefiant.io",
    "cryptonews.com",
    "coinTelegraph.com",
    // video
    "drakula.app", 
    "stream.warpcast.com", 
    "streamm.tv",
    "video.twimg.com",
    "stream.takoassets.so",
    "video.twimg.com",
    // images
    "i.imgur.com", 
    "imagedelivery.net",
    "images.farcaster.phaver.com",
    "ik.imagekit.io",
    "vz-629bcc17-285.b-cdn.net",
    //polls
    "frames.weponder.io", 
    // subscriptions
    "hypersub.withfabric.xyz" , 
    // events
    "events.xyz", 
    // bounties
    "bountycaster.xyz", 
    // projects
    "launchcaster.xyz"
];


exports.endpointOptions = [
    'casts/feed/for-you',
    // 'casts/feed/reranked', 
    // 'casts/feed/similar',
    'casts/feed/trending',
    'casts/feed/popular'
    // 'casts/search/semantic'
]

exports.scoringOptions = {
    "casts/feed/trending": ['30min', '1hour', '3hours', '1day', '3days'],
    "casts/feed/popular": ['30min', '1hour', '3hours', '1day', '3days'],
    "casts/feed/for-you": ['like', 'recast', 'reply', 'all'],
    "casts/feed/reranked": ['all'],
    "casts/feed/similar": [],
    "casts/search/semantic": []
  };

exports.hideScoringOptions = ['casts/search/semantic', 'casts/feed/similar'];

exports.computeUnitsPerApiCall = {
    'casts-feed-trending': 15,
    'casts-feed-popular': 15,
    'casts-feed-for-you': 100,
    'casts-feed-reranked': 100,
    'casts-feed-similar': 100,
    'casts-semantic-search': 50,
    'casts-labels-for-items': 25,
    'casts-labels-for-text': 25,
    'casts-labels-top-items': 25,
    'users-feed-similar': 50,
    'users-semantic-search': 50,
    'users-labels-for-users': 50,
    'users-labels-top-users': 50
}

exports.mbdUsagePlans = {
    "BUILDR" : {
        "cuQuota": 1000000,
        "feedQuota": 1
    },
    "Standard": {
        "cuQuota": 50000000,
        "feedQuota": 2
    },
    "Pro": {
        "cuQuota": 100000000,
        "feedQuota": 20
    },
    "Enterprise": { 
        "cuQuota": null,
        "feedQuota": null
    }
}